import Vue from 'vue'
import App from './App.vue'
import router from "./router"; 
/* 引入公共样式 */
import '../src/assets/css/public.css'
/* 引入element */
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'; import 'animate.css';
// 引入动画组件
import "animate.css";
// 引入组件实现图片懒加载
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "dist/error.png",
  loading: "dist/loading.gif",
  attempt: 1,
});
Vue.config.productionTip = false
Vue.use(Element);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
